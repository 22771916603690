import React, { useCallback, useEffect, useRef } from 'react';
import * as S from './styles';
import * as Tabs from 'components/Shared/Tabs';
import { FormPageHeader } from 'components/Shared';
import {
  useCarriers,
  useCompanies,
  useOperationTypes,
  useVehicleTypes,
} from 'hooks';
import { RootState } from 'store';
import { useSelector } from 'react-redux';

import CarriersManager, { Ref as CarriersManagerRef } from './CarriersManager';
import CompaniesManager, {
  Ref as CompaniesManagerRef,
} from './CompaniesManager';
import VehicleTypesManager, {
  Ref as VehicleTypesManagerRef,
} from './VehicleTypesManager';
import OperationTypesManager, {
  Ref as OperationTypesManagerRef,
} from './OperationTypesManager';

interface Props {
  onUpdate: () => void;
  warehouseId: string;
}

export const RulesManager: React.FC<Props> = (props) => {
  const carriersManagerRef = useRef<CarriersManagerRef>(null);
  const companiesManagerRef = useRef<CompaniesManagerRef>(null);
  const operationTypesManagerRef = useRef<OperationTypesManagerRef>(null);
  const vehicleTypesManagerRef = useRef<VehicleTypesManagerRef>(null);
  const tabsOutletRef = useRef<Tabs.Ref>(null);

  const { loading } = useSelector((state: RootState) => state.fetchWarehouse);

  const { fetchCarriers } = useCarriers();
  const { fetchCompanies } = useCompanies();
  const { fetchOperationTypes } = useOperationTypes();
  const { fetchVehicleTypes } = useVehicleTypes();

  // We will dispatch this action here on top level
  // to avoid dispatching multiple times down the tree

  const fetchLists = useCallback((): void => {
    fetchCarriers();
    fetchCompanies();
    fetchOperationTypes();
    fetchVehicleTypes();
  }, [fetchCarriers, fetchCompanies, fetchOperationTypes, fetchVehicleTypes]);

  useEffect(() => {
    fetchLists();
  }, [fetchLists]);

  return (
    <S.MainPanel>
      <FormPageHeader
        title="Restrições"
        icon={<S.BookContentIcon />}
        isLoading={loading}
        removeSpacing
        showBorder={false}
      />
      <S.TabedContainer>
        <Tabs.Controller
          onTabChange={(i) => tabsOutletRef?.current?.selectTab(i)}
        >
          <Tabs.ModalTab title="Transportadoras" />
          <Tabs.ModalTab title="Clientes" />
          <Tabs.ModalTab title="Tipos de operação" />
          <Tabs.ModalTab title="Tipos de veículo" />
        </Tabs.Controller>
        <Tabs.Outlet ref={tabsOutletRef}>
          <CarriersManager ref={carriersManagerRef} {...props} />
          <CompaniesManager ref={companiesManagerRef} {...props} />
          <OperationTypesManager ref={operationTypesManagerRef} {...props} />
          <VehicleTypesManager ref={vehicleTypesManagerRef} {...props} />
        </Tabs.Outlet>
      </S.TabedContainer>
    </S.MainPanel>
  );
};
