import { combineReducers } from 'redux';

import {
  auth,
  generatePasswordResetToken,
  impersonate,
  login,
  logout,
  resetPassword,
  updateAccount,
} from './auth';
import { paginationParamsCache, warehouseScheduleParamsCache } from './cache';
import { fetchAddressByZipcode } from './addressLookup';
import {
  createAdmin,
  deleteAdmin,
  fetchAdmin,
  paginateAdmins,
  updateAdmin,
} from './admins';
import {
  createCancelationReason,
  fetchCancelationReason,
  listCancelationReasons,
  paginateCancelationReasons,
  updateCancelationReason,
} from './cancelationReasons';
import {
  createCargoType,
  fetchCargoType,
  listCargoTypes,
  paginateCargoTypes,
  updateCargoType,
} from './cargoTypes';
import {
  createCarrier,
  fetchCarrier,
  listCarriers,
  paginateCarriers,
  updateCarrier,
} from './carriers';
import {
  createCarrierMember,
  deleteCarrierMember,
  fetchCarrierMember,
  paginateCarrierMembers,
  updateCarrierMember,
} from './carrierMembers';
import {
  createCompany,
  fetchCompany,
  listCompanies,
  paginateCompanies,
  updateCompany,
} from './companies';
import {
  createCompanyMember,
  deleteCompanyMember,
  fetchCompanyMember,
  paginateCompanyMembers,
  updateCompanyMember,
} from './companyMembers';
import { listCountries } from './countries';
import {
  createDock,
  fetchDock,
  listDocks,
  paginateDocks,
  updateDock,
} from './docks';
import { createDockCarrier, deleteDockCarrier } from './dockCarriers';
import { createDockCompany, deleteDockCompany } from './dockCompanies';
import {
  createDockOperationType,
  deleteDockOperationType,
} from './dockOperationTypes';
import {
  createDockReservedInterval,
  deleteDockReservedInterval,
  fetchDockReservedInterval,
  listDockReservedIntervals,
  paginateDockReservedIntervals,
  updateDockReservedInterval,
} from './dockReservedIntervals';
import {
  fetchOperationType,
  listOperationTypes,
  paginateOperationTypes,
  updateOperationType,
} from './operationTypes';
import {
  createOrder,
  fetchOrder,
  paginateOrders,
  updateOrderCancelationReason,
  updateOrderDriverAndVehicle,
  updateOrderEvents,
  updateOrderNoShow,
  exportOrders,
} from './orders';
import { fetchOrderType, listOrderTypes } from './orderTypes';
import {
  listSchedulingDocks,
  listSchedulingVehicleSetups,
  listWarehouseScheduleEntries,
} from './scheduling';
import {
  createVehicleSetup,
  fetchVehicleSetup,
  listVehicleSetups,
  paginateVehicleSetups,
  updateVehicleSetup,
} from './vehicleSetups';
import {
  createVehicleType,
  fetchVehicleType,
  listVehicleTypes,
  paginateVehicleTypes,
  updateVehicleType,
} from './vehicleTypes';
import {
  createWarehouse,
  fetchWarehouse,
  listWarehouses,
  paginateWarehouses,
  updateWarehouse,
} from './warehouses';
import {
  createWarehouseMember,
  deleteWarehouseMember,
  fetchWarehouseMember,
  paginateWarehouseMembers,
  updateWarehouseMember,
  exportWarehouseMembers,
} from './warehouseMembers';
import {
  createWarehouseVehicleSetup,
  deleteWarehouseVehicleSetup,
  fetchWarehouseVehicleSetup,
  listWarehouseVehicleSetups,
  paginateWarehouseVehicleSetups,
  updateWarehouseVehicleSetup,
} from './warehouseVehicleSetups';
import {
  createWarehouseMemberAccess,
  deleteWarehouseMemberAccess,
  fetchWarehouseMemberAccess,
  listWarehouseMemberAccesses,
  paginateWarehouseMemberAccesses,
} from './warehouseMemberAccesses';
import {
  createCompanyMemberAccess,
  deleteCompanyMemberAccess,
  fetchCompanyMemberAccess,
  listCompanyMemberAccesses,
  paginateCompanyMemberAccesses,
} from './companyMemberAccesses';
import {
  createCompanyWarehouseAccess,
  deleteCompanyWarehouseAccess,
  fetchCompanyWarehouseAccess,
  listCompanyWarehouseAccesses,
  paginateCompanyWarehouseAccesses,
} from './companyWarehouseAccesses';

import {
  createWarehouseCarrier,
  deleteWarehouseCarrier,
} from './warehouseCarriers';

import {
  createWarehouseCompany,
  deleteWarehouseCompany,
} from './warehouseCompanies';

import {
  createWarehouseOperationType,
  deleteWarehouseOperationType,
} from './warehouseOperationTypes';

import {
  createWarehouseVehicleType,
  deleteWarehouseVehicleType,
} from './warehouseVehicleTypes';

import recallSlice from './leftPanel/recall';

export default combineReducers({
  // auth
  auth,
  generatePasswordResetToken,
  impersonate,
  login,
  logout,
  resetPassword,
  updateAccount,
  // cache
  paginationParamsCache,
  warehouseScheduleParamsCache,
  // address lookup
  fetchAddressByZipcode,
  // admins
  createAdmin,
  deleteAdmin,
  fetchAdmin,
  paginateAdmins,
  updateAdmin,
  // cancelation reasons
  createCancelationReason,
  fetchCancelationReason,
  listCancelationReasons,
  paginateCancelationReasons,
  updateCancelationReason,
  // cargo types
  createCargoType,
  fetchCargoType,
  listCargoTypes,
  paginateCargoTypes,
  updateCargoType,
  // carriers
  createCarrier,
  fetchCarrier,
  listCarriers,
  paginateCarriers,
  updateCarrier,
  // carrier members
  createCarrierMember,
  deleteCarrierMember,
  fetchCarrierMember,
  paginateCarrierMembers,
  updateCarrierMember,
  // companies
  createCompany,
  fetchCompany,
  listCompanies,
  paginateCompanies,
  updateCompany,
  // company members
  createCompanyMember,
  deleteCompanyMember,
  fetchCompanyMember,
  paginateCompanyMembers,
  updateCompanyMember,
  // countries
  listCountries,
  // docks
  createDock,
  fetchDock,
  listDocks,
  paginateDocks,
  updateDock,
  // docks and carriers
  createDockCarrier,
  deleteDockCarrier,
  // docks and companies
  createDockCompany,
  deleteDockCompany,
  // docks and operation types
  createDockOperationType,
  deleteDockOperationType,
  // dock reserved intervals
  createDockReservedInterval,
  deleteDockReservedInterval,
  fetchDockReservedInterval,
  listDockReservedIntervals,
  paginateDockReservedIntervals,
  updateDockReservedInterval,
  // operation types
  fetchOperationType,
  listOperationTypes,
  paginateOperationTypes,
  updateOperationType,
  // orders
  createOrder,
  fetchOrder,
  paginateOrders,
  updateOrderCancelationReason,
  updateOrderDriverAndVehicle,
  updateOrderEvents,
  updateOrderNoShow,
  exportOrders,
  // order types
  fetchOrderType,
  listOrderTypes,
  // scheduling
  listSchedulingDocks,
  listSchedulingVehicleSetups,
  listWarehouseScheduleEntries,
  // vehicle setups
  createVehicleSetup,
  fetchVehicleSetup,
  listVehicleSetups,
  paginateVehicleSetups,
  updateVehicleSetup,
  // vehicle types
  createVehicleType,
  fetchVehicleType,
  listVehicleTypes,
  paginateVehicleTypes,
  updateVehicleType,
  // warehouses
  createWarehouse,
  fetchWarehouse,
  listWarehouses,
  paginateWarehouses,
  updateWarehouse,
  // warehouse members
  createWarehouseMember,
  deleteWarehouseMember,
  fetchWarehouseMember,
  exportWarehouseMembers,
  paginateWarehouseMembers,
  updateWarehouseMember,
  // warehouse vehicle setups
  createWarehouseVehicleSetup,
  deleteWarehouseVehicleSetup,
  fetchWarehouseVehicleSetup,
  listWarehouseVehicleSetups,
  paginateWarehouseVehicleSetups,
  updateWarehouseVehicleSetup,
  // warehouse member accesses
  createWarehouseMemberAccess,
  deleteWarehouseMemberAccess,
  fetchWarehouseMemberAccess,
  listWarehouseMemberAccesses,
  paginateWarehouseMemberAccesses,
  //company member accesses
  createCompanyMemberAccess,
  deleteCompanyMemberAccess,
  fetchCompanyMemberAccess,
  listCompanyMemberAccesses,
  paginateCompanyMemberAccesses,
  //company warehouse accesses
  createCompanyWarehouseAccess,
  deleteCompanyWarehouseAccess,
  fetchCompanyWarehouseAccess,
  listCompanyWarehouseAccesses,
  paginateCompanyWarehouseAccesses,
  // warehouse rules carrier
  createWarehouseCarrier,
  deleteWarehouseCarrier,
  // warehouse rules carrier
  createWarehouseCompany,
  deleteWarehouseCompany,
  // warehouse rules vehicle type
  createWarehouseVehicleType,
  deleteWarehouseVehicleType,
  // warehouse rules carrier
  createWarehouseOperationType,
  deleteWarehouseOperationType,
  /**SLICES */ 

  // recall slice
  recallSlice,
});
