import { SelectOption } from 'contracts/Common';
import { useAuth } from 'hooks/auth';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppDispatch, RootState } from 'store';
import { ListWarehousesActions as MainActions } from 'store/ducks/warehouses';
import { FetchWarehouseActions as FetchActions } from 'store/ducks/warehouses';
import { Formatter } from 'utils';

export const useWarehouses = () => {
  const dispatch: AppDispatch = useDispatch();
  const { profile } = useAuth();

  const { data: warehouses, loading: loadingWarehouses } = useSelector(
    (state: RootState) => state.listWarehouses
  );
  const { data: warehouse, loading: loadingWarehouse } = useSelector(
    (state: RootState) => state.fetchWarehouse
  );

  const [options, setOptions] = useState<SelectOption[]>([]);

  const fetchWarehouses = useCallback((): void => {
    dispatch(MainActions.request());
  }, [dispatch]);

  const fetchWarehouse = useCallback(
    (id: string | number): void => {
      dispatch(FetchActions.request(id));
    },
    [dispatch]
  );

  const resetWarehouse = useCallback(() => {
    dispatch(FetchActions.reset());
  }, [dispatch]);

  const onWarehousesLoad = useCallback((): void => {
    if (!profile) return;
    const { warehouse, warehouseAccess } = profile;

    if (warehouse?.id) {
      const options = warehouses
        .filter(({ id }) => id === warehouse.id)
        .map(({ id, tradeName, documentType, document }) => ({
          value: id,
          label: `${tradeName} - ${Formatter.document(document, documentType)}`,
        }));

      if (warehouseAccess?.length) {
        const otherOptions = warehouses
          .filter(({ id }) =>
            warehouseAccess.map(({ warehouseId }) => warehouseId).includes(id)
          )
          .map(({ id, tradeName, documentType, document }) => ({
            value: id,
            label: `${tradeName} - ${Formatter.document(
              document,
              documentType
            )}`,
          }));

        setOptions([...otherOptions, ...options]);
      } else {
        setOptions(options);
      }
    } else {
      setOptions(
        warehouses.map(({ id, tradeName, documentType, document }) => ({
          value: id,
          label: `${tradeName} - ${Formatter.document(document, documentType)}`,
        }))
      );
    }
  }, [warehouses, profile]);

  useEffect(() => {
    onWarehousesLoad();
  }, [onWarehousesLoad]);

  useEffect(() => {
    return () => {
      setOptions([]);
      dispatch(MainActions.reset());
    };
  }, [dispatch]);

  return {
    warehouses,
    warehouseOptions: options,
    loadingWarehouses,
    warehouse,
    loadingWarehouse,
    fetchWarehouses,
    fetchWarehouse,
    resetWarehouse,
  };
};

export type WarehousesHook = ReturnType<typeof useWarehouses>;
