import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import {
  FileInput,
  FormPageHeader,
  Input,
  MaskedInput,
  Select,
  Textarea,
} from 'components/Shared';
import {
  FORM_BACK_ACTION,
  OperationTypeEnum,
  OrderTypeEnum,
  SCHEDULING_DATE_PICKER_INTERVAL,
} from 'constants/Common';
import { SelectOption } from 'contracts/Common';
import { FindSchedulingDocks } from 'contracts/Scheduling';
import { addDays, startOfDay } from 'date-fns';
import {
  useAuth,
  useCargoTypes,
  useCarriers,
  useCompanies,
  useOperationTypes,
  useOrderTypes,
  useSchedulingDocks,
  useSchedulingVehicleSetups,
  useValidation,
  useVehicleTypes,
  useWarehouses,
} from 'hooks';
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notify } from 'services';
import { AppDispatch, RootState } from 'store';
import { CreateOrderActions } from 'store/ducks/orders';
import { Formatter } from 'utils';
import { CreateOrderValidator } from 'validators/Orders';
import OrderItems, { Ref as OrderItemsRef } from './OrderItems';
import * as S from './styles';
import { FilesList } from './FilesList';
import { convertDataToFormData } from './utils/convertDataToFormData';

interface Props {
  onCreate?: () => void;
  backActionPathname?: string;
}

interface GeneralState {
  operationTypeId: string | number;
  orderTypeId: string | number;
  vehicleTypeId: string | number;
  cargoTypeId: string | number;
  dockId: string | number;
  scheduledAt: string;
}

interface WeightAndDuration {
  weightCapacity: number;
  duration: number;
}

// today date in yyyy-mm-dd format
const MIN_DATE = new Date().toISOString().split('T')[0];
const MIN_DATE_CARRIER = addDays(startOfDay(new Date()), 1)
  .toISOString()
  .split('T')[0];
// 15 days from today
const MAX_DATE = addDays(new Date(), SCHEDULING_DATE_PICKER_INTERVAL)
  .toISOString()
  .split('T')[0];
const MAX_DATE_CARRIER = addDays(
  addDays(new Date(), 1),
  SCHEDULING_DATE_PICKER_INTERVAL
)
  .toISOString()
  .split('T')[0];

export const OrderCreationForm: React.FC<Props> = ({
  onCreate,
  backActionPathname = '/agendamento',
}) => {
  const dispatch: AppDispatch = useDispatch();
  const formRef = useRef<FormHandles>(null);
  const orderItemsRef = useRef<OrderItemsRef>(null);
  const [files, setFiles] = useState<File[]>([]);
  const fileRef = useRef<File[]>();

  const { loading: creatingOrder, validationErrors } = useSelector(
    (state: RootState) => state.createOrder
  );

  const [docksQuery, setDocksQuery] = useState<Partial<FindSchedulingDocks>>(
    {}
  );
  const [generalState, setGeneralState] = useState<Partial<GeneralState>>({});
  const [weightAndDuration, setWeightAndDuration] = useState<
    Partial<WeightAndDuration>
  >({});

  const { profile, userBelongsToAnyOf } = useAuth();
  const { handleFormErrors, handleApiErrors } = useValidation();
  const { operationTypeOptions, loadingOperationTypes, fetchOperationTypes } =
    useOperationTypes();
  const { orderTypeOptions, loadingOrderTypes, fetchOrderTypes } =
    useOrderTypes();
  const { companyOptions, loadingCompanies, fetchCompanies } = useCompanies();
  const { carrierOptions, loadingCarriers, fetchCarriers } = useCarriers();
  const {
    warehouseOptions,
    loadingWarehouses,
    loadingWarehouse,
    warehouse,
    fetchWarehouses,
    fetchWarehouse,
    resetWarehouse,
  } = useWarehouses();
  const {
    schedulingVehicleSetups,
    loadingSchedulingVehicleSetups,
    fetchSchedulingVehicleSetups,
  } = useSchedulingVehicleSetups();
  const { vehicleTypeOptions, loadingVehicleTypes, fetchVehicleTypes } =
    useVehicleTypes();
  const { cargoTypeOptions, loadingCargoTypes, fetchCargoTypes } =
    useCargoTypes();
  const {
    schedulingDocksOptions,
    loadingSchedulingDocks,
    fetchSchedulingDocks,
  } = useSchedulingDocks();

  const rules = useMemo(
    () => ({
      operationTypeOptions:
        warehouse?.operationTypeOptions ?? operationTypeOptions,
      vehicleTypeOptions: warehouse?.vehicleTypesOptions ?? vehicleTypeOptions,
      companyOptions: warehouse?.companiesOptions ?? companyOptions,
      carrierOptions: warehouse?.carriersOptions ?? carrierOptions,
    }),
    [
      operationTypeOptions,
      vehicleTypeOptions,
      companyOptions,
      carrierOptions,
      warehouse,
    ]
  );

  const carrierOptionsRules = useMemo(() => {
    if (!profile) return;
    const { carrier } = profile;

    if (carrier?.id) {
      return rules.carrierOptions.filter((op) => op.value === carrier.id);
    }

    return rules.carrierOptions;
  }, [rules.carrierOptions]);

  const companiesOptionsRules = useMemo(() => {
    if (!profile) return;
    const { company, companyAccess } = profile;

    if (company?.id) {
      const options = rules.companyOptions.filter(
        (op) => op.value === company.id
      );

      if (companyAccess?.length) {
        const otherOptions = rules.companyOptions.filter(({ value }) =>
          companyAccess
            .map(({ companyId }) => companyId)
            .includes(Number(value))
        );
        return [...options, ...otherOptions];
      }

      return options;
    }

    return rules.companyOptions;
  }, [rules.companyOptions]);

  const handleFileChange = useCallback(
    (file: FileList | null) => {
      if (file?.length) {
        const alreadyHasFile = files.some(
          (fileFind) => fileFind.name === file[0].name
        );

        if (!alreadyHasFile) {
          fileRef.current = fileRef?.current
            ? [...fileRef.current, file[0]]
            : [file[0]];
          setFiles((prev) => [...prev, file[0]]);
        }
      }
    },
    [files]
  );

  const handleDeleteFile = useCallback(
    (fileName: string) => {
      fileRef.current = files.filter((file) => file.name !== fileName);
      setFiles((prev) => prev.filter((file) => file.name !== fileName));
    },
    [files]
  );

  const onWarehouseChange = useCallback(
    (option: SelectOption | null): void => {
      formRef.current?.setFieldValue('vehicleTypeId', null);
      formRef.current?.setFieldValue('carrierId', null);
      formRef.current?.setFieldValue('companyId', null);
      formRef.current?.setFieldValue('operationTypeId', null);

      setDocksQuery((state) => ({ ...state, warehouseId: option?.value }));
      if (option?.value) fetchWarehouse(option.value);
      fetchSchedulingVehicleSetups({
        excludeBlocked: true,
        warehouseId: option?.value,
      });
    },
    [fetchSchedulingVehicleSetups]
  );

  const onVehicleTypeChange = useCallback(
    (option: SelectOption | null): void => {
      formRef.current?.setFieldValue('cargoTypeId', null);
      return setGeneralState((state) => ({
        ...state,
        vehicleTypeId: option?.value,
      }));
    },
    []
  );

  const onCargoTypeChange = useCallback((option: SelectOption | null): void => {
    formRef.current?.setFieldValue('dockAndTimeframe', null);
    setGeneralState((state) => ({
      ...state,
      cargoTypeId: option?.value,
    }));
  }, []);

  const onDateChange = useCallback((e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    formRef.current?.setFieldValue('dockAndTimeframe', null);
    return setDocksQuery((state) => ({
      ...state,
      date: !!value ? value : undefined,
    }));
  }, []);

  const onDockAndTimeframeChange = useCallback(
    (option: SelectOption | null): void => {
      const value = option?.value;

      if (!value) {
        return setGeneralState((state) => ({
          ...state,
          dockId: undefined,
          scheduledAt: undefined,
        }));
      }

      const [dockId, scheduledAt] = `${value}`.split('|');

      return setGeneralState((state) => ({
        ...state,
        dockId,
        scheduledAt,
      }));
    },
    []
  );

  const updateWeightCapacityAndDuration = useCallback((): void => {
    const { vehicleTypeId, cargoTypeId, orderTypeId } = generalState;
    if (!vehicleTypeId || !cargoTypeId || !orderTypeId) {
      return setWeightAndDuration((state) => ({
        ...state,
        weightCapacity: undefined,
        duration: undefined,
      }));
    }

    const vehicleSetup = schedulingVehicleSetups.find(
      (setup) =>
        setup.vehicleType.id === vehicleTypeId &&
        setup.cargoType.id === cargoTypeId
    );

    if (!vehicleSetup) return;

    const duration =
      orderTypeId === OrderTypeEnum.CARREGAMENTO
        ? vehicleSetup.loadDuration
        : vehicleSetup.unloadDuration;

    setWeightAndDuration((state) => ({
      ...state,
      weightCapacity: vehicleSetup.weightCapacity,
      duration,
    }));

    setDocksQuery((state) => ({
      ...state,
      duration,
    }));
  }, [generalState, schedulingVehicleSetups]);

  const onSuccess = useCallback((): void => {
    onCreate && onCreate();
  }, [onCreate]);

  const onSubmit = useCallback(
    async (data: any): Promise<void> => {
      const { dockId, scheduledAt } = generalState;
      const { weightCapacity, duration } = weightAndDuration;
      formRef.current?.setErrors({});
      const documents = fileRef.current;

      Object.assign(data, {
        dockId,
        scheduledAt: scheduledAt ? new Date(scheduledAt) : undefined,
        weightCapacity,
        duration,
        documents,
      });
      const { schema } = new CreateOrderValidator({
        operationTypeId: data.operationTypeId,
        isCarrier: userBelongsToAnyOf('carrierMember'),
      });

      try {
        const validData = await schema.validate(data, {
          abortEarly: false,
        });

        // manual validations

        const orderItems = orderItemsRef.current?.getItems() || [];

        if (orderItems.length === 0) {
          return notify('error', 'Não há items de agendamento cadastrados');
        }

        const totalWeight = orderItems.reduce(
          (acc, item) => acc + item.weight,
          0
        );

        if (totalWeight > validData.weightCapacity) {
          const weightCapacityString = Formatter.weight(
            validData.weightCapacity
          );

          return notify(
            'error',
            `O peso total dos items excede ${weightCapacityString}`
          );
        }
        const { documents, ...rest } = validData;

        const formData = convertDataToFormData({
          ...rest,
          items: orderItems,
          documents: fileRef.current,
        });

        dispatch(CreateOrderActions.request(formData, onSuccess));
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    },
    [
      generalState,
      weightAndDuration,
      userBelongsToAnyOf,
      dispatch,
      onSuccess,
      handleFormErrors,
    ]
  );

  useEffect(() => {
    fetchOrderTypes();
  }, [fetchOrderTypes]);

  useEffect(() => {
    fetchOperationTypes({ excludeBlocked: true });
  }, [fetchOperationTypes]);

  useEffect(() => {
    fetchCompanies();
  }, [fetchCompanies]);

  useEffect(() => {
    fetchCarriers();
  }, [fetchCarriers]);

  useEffect(() => {
    fetchWarehouses();
  }, [fetchWarehouses]);

  useEffect(() => {
    fetchVehicleTypes({ excludeBlocked: false });
  }, [fetchVehicleTypes]);

  useEffect(() => {
    fetchCargoTypes({ excludeBlocked: false });
  }, [fetchCargoTypes]);

  useEffect(() => {
    fetchSchedulingDocks(docksQuery);
  }, [fetchSchedulingDocks, docksQuery]);

  useEffect(() => {
    updateWeightCapacityAndDuration();
  }, [updateWeightCapacityAndDuration]);

  useEffect(() => {
    handleApiErrors(validationErrors, formRef);
  }, [handleApiErrors, validationErrors]);

  useEffect(() => resetWarehouse, [resetWarehouse]);

  return (
    <S.Container>
      {/* <S.Panel>
        <pre>
          {JSON.stringify(
            { docksQuery, generalState, weightAndDuration },
            null,
            2
          )}
        </pre>
      </S.Panel> */}
      <Form ref={formRef} onSubmit={onSubmit}>
        <S.Panel>
          <FormPageHeader
            title="Novo agendamento"
            icon={<S.CalendarIcon />}
            actions={
              <S.LinkButton
                size="small"
                to={backActionPathname}
                style={{ pointerEvents: creatingOrder ? 'none' : 'auto' }}
              >
                <S.ArrowLeftIcon /> Voltar
              </S.LinkButton>
            }
          />
          <S.FormRow>
            <Input name="driverName" label="Nome do motorista" />
            <MaskedInput
              name="cellPhone"
              label="Celular"
              mask="(99) 99999-9999"
            />
            <MaskedInput
              name="driverDocument"
              label="CPF do motorista"
              mask="999.999.999-99"
            />
            <Select
              name="warehouseId"
              label="Armazém"
              options={warehouseOptions}
              isLoading={loadingWarehouses}
              onChange={onWarehouseChange}
            />
            <Select
              name="carrierId"
              label="Transportadora"
              options={carrierOptionsRules}
              isLoading={loadingCarriers || loadingWarehouse}
              isDisabled={!Boolean(warehouse)}
              onChange={(option) => {
                setDocksQuery((state) => ({
                  ...state,
                  carrierId: option?.value,
                }));
              }}
            />
          </S.FormRow>

          <S.FormRow>
            <Select
              name="operationTypeId"
              label="Tipo de operação"
              options={rules.operationTypeOptions}
              isLoading={loadingOperationTypes || loadingWarehouse}
              isDisabled={!Boolean(warehouse)}
              onChange={(option) => {
                setGeneralState((state) => ({
                  ...state,
                  operationTypeId: option?.value,
                }));
                setDocksQuery((state) => ({
                  ...state,
                  operationTypeId: option?.value,
                }));
              }}
            />
            <Select
              name="orderTypeId"
              label="Tipo de ordem"
              options={orderTypeOptions}
              isLoading={loadingOrderTypes}
              onChange={(option) => {
                setGeneralState((state) => ({
                  ...state,
                  orderTypeId: option?.value,
                }));
                setDocksQuery((state) => ({
                  ...state,
                  orderTypeId: option?.value,
                }));
              }}
            />
            <Select
              name="companyId"
              label="Cliente"
              options={companiesOptionsRules}
              isLoading={loadingCompanies || loadingWarehouse}
              isDisabled={!Boolean(warehouse)}
              onChange={(option) => {
                setDocksQuery((state) => ({
                  ...state,
                  companyId: option?.value,
                }));
              }}
            />
            <Select
              name="vehicleTypeId"
              label="Tipo de veículo"
              options={rules.vehicleTypeOptions.filter((option) => {
                // only show hevicle types for existing vehicle setups
                return schedulingVehicleSetups.some((setup) => {
                  return setup.vehicleType.id === Number(option.value);
                });
              })}
              isLoading={
                loadingVehicleTypes ||
                loadingSchedulingVehicleSetups ||
                loadingWarehouse
              }
              isDisabled={
                !schedulingVehicleSetups.length || !Boolean(warehouse)
              }
              onChange={onVehicleTypeChange}
            />
          </S.FormRow>

          {generalState.operationTypeId === OperationTypeEnum.DEVOLUCAO && (
            <S.FormRow>
              <Input
                name="devolutionNumber"
                label="Número da ordem de devolução"
              />
            </S.FormRow>
          )}
          {generalState.operationTypeId === OperationTypeEnum.EXPORTACAO && (
            <S.FormRow>
              <Input name="exportationContainer" label="Container" />
              <Input name="exportationSecuritySeal" label="Lacre" />
            </S.FormRow>
          )}
          {generalState.operationTypeId === OperationTypeEnum.IMPORTACAO && (
            <S.FormRow>
              <Input name="importationDeclaration" label="Declaração" />
              <Input name="importationOrder" label="Pedido" />
              <Input name="importationSequential" label="Sequencial" />
              <Input name="importationContainer" label="Container" />
            </S.FormRow>
          )}

          <S.FormRow>
            <Select
              name="cargoTypeId"
              label="Tipo de carga"
              options={cargoTypeOptions.filter((option) => {
                // only show cargo types for existing vehicle setups and
                // for the selected vehicle type
                return schedulingVehicleSetups.some((setup) => {
                  return (
                    setup.cargoType.id === option.value &&
                    setup.vehicleType.id === Number(generalState.vehicleTypeId)
                  );
                });
              })}
              isLoading={loadingCargoTypes || loadingSchedulingVehicleSetups}
              isDisabled={!generalState.vehicleTypeId}
              onChange={onCargoTypeChange}
            />
            <Input name="vehiclePlate" label="Placa do veículo" />
            <Input
              name="date"
              type="date"
              label="Data"
              min={
                userBelongsToAnyOf('carrierMember', 'companyMember') &&
                !Boolean(profile?.allowToday)
                  ? MIN_DATE_CARRIER
                  : MIN_DATE
              }
              max={
                userBelongsToAnyOf('carrierMember', 'companyMember')
                  ? MAX_DATE_CARRIER
                  : MAX_DATE
              }
              onChange={onDateChange}
            />
            <Select
              name="dockAndTimeframe"
              label="Doca e horário"
              options={schedulingDocksOptions}
              isLoading={loadingSchedulingDocks}
              isDisabled={!schedulingDocksOptions.length}
              onChange={onDockAndTimeframeChange}
            />
          </S.FormRow>

          <S.FormRow>
            <Textarea
              name="observation"
              label="Observações"
              style={{ resize: 'none' }}
            />
            <Textarea
              name="material"
              label="Materiais"
              style={{ resize: 'none' }}
            />
          </S.FormRow>
          <S.FormRow>
            <FileInput
              name="documents"
              label="Upload de Documentos"
              onChange={(e) => handleFileChange(e.currentTarget.files)}
              auxText="Apenas Arquivos (.jpg, .png ou .pdf)"
              onlyIcon
              accept=".png, .jpeg, .jpg, .pdf"
            />
          </S.FormRow>
          {files.length > 0 && (
            <FilesList files={files} onFileDelete={handleDeleteFile} />
          )}
        </S.Panel>
      </Form>
      <OrderItems
        ref={orderItemsRef}
        weightCapacity={weightAndDuration.weightCapacity}
        duration={weightAndDuration.duration}
      />
      <S.Panel>
        <S.FormActions style={{ marginTop: 0 }}>
          <S.LinkButton
            mood="light"
            to={backActionPathname}
            style={{ pointerEvents: creatingOrder ? 'none' : 'auto' }}
          >
            {FORM_BACK_ACTION}
          </S.LinkButton>
          <S.Button type="submit" onClick={formRef.current?.submitForm}>
            {creatingOrder ? <S.ActivityIndicator /> : 'Salvar'}
          </S.Button>
        </S.FormActions>
      </S.Panel>
    </S.Container>
  );
};
